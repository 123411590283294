import { Link } from "react-router-dom";
import { Section } from "./Services.styled";

import parking1 from "./images/parking-1.jpg";
import parking2 from "./images/parking-2.jpg";
import parking3 from "./images/parking-3.jpg";
import parking4 from "./images/parking-4.jpg";

export const ParkingWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Parking Renovation</h2>
          <div className="gallery">
            <img src={parking1} alt="Parking Renovation" />
            <img src={parking2} alt="Parking Renovation" />
            <img src={parking3} alt="Parking Renovation" />
            <img src={parking4} alt="Parking Renovation" />
          </div>
        </div>
        <Link className="button" to="/">
          Go Back
        </Link>
      </div>
    </Section>
  );
};
