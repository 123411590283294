import { Link } from "react-router-dom";
import { Section } from "./Services.styled";

import interior1 from "./images/interior-1.jpg";
import interior2 from "./images/interior-2.jpg";
import interior3 from "./images/interior-3.jpg";
import interior4 from "./images/interior-4.jpg";

export const InteriorWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Interior Renovation</h2>
          <div className="gallery">
            <img src={interior1} alt="Interior Renovation" />
            <img src={interior2} alt="Interior Renovation" />
            <img src={interior3} alt="Interior Renovation" />
            <img src={interior4} alt="Interior Renovation" />
          </div>
        </div>
        <Link className="button" to="/">
          Go Back
        </Link>
      </div>
    </Section>
  );
};
