import { Link } from "react-router-dom";
import { Section } from "./Home.styled";
import aboutbg from "./images/about.jpg";
import pastwork from "./images/past-work.jpg";
import masonry from "./images/exterior-renovations-toronto.jpg";
import interior from "./images/interior-renovations-toronto.jpg";
import kitchen from "./images/custom-kitchens-toronto.jpg";
import basement from "./images/finished-basement-toronto.jpg";
import bathroom from "./images/bathroom-renovations-toronto.jpg";
import parking from "../Services/images/parking-1.jpg";

export const Home = () => {
  return (
    <Section>
      <div id="page-wrapper">
        <section id="banner">
          <div className="inner">
            <h2>Mason OM</h2>
            <p>
              LICENSED, INSURED, EXPERT HOME RENOVATION <br /> Over 9 Years of
              Expertise and Trust in the Industry
            </p>
            <ul className="actions special">
              <li>
                <a href="#contact" className="button primary">
                  Get in Touch
                </a>
              </li>
            </ul>
          </div>
          <a href="#two" className="more scrolly">
            Learn More
          </a>
        </section>

        <section id="two" className="wrapper alt style2">
          <section className="spotlight">
            <div className="image">
              <img src={aboutbg} alt="house1" />
            </div>
            <div className="content">
              <h2>
                About Us <br />
              </h2>
              <p>
                Welcome to Mason OM, a leading home remodeling company since
                2015. With nearly two decades of experience, we specialize in
                transforming living spaces with creativity and superior
                craftsmanship. Our dedicated team brings your vision to life,
                whether it's a kitchen, bathroom, or entire home renovation. At
                Mason OM, we prioritize quality materials and innovation to
                redefine excellence in home remodeling. Trust us to make your
                dream home a reality.
              </p>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <img src={pastwork} alt="house2" />
            </div>
            <div className="content">
              <h2>
                Past Work
                <br />
              </h2>
              <p>
                <Link className="button" to="/pastwork">
                  View Photos
                </Link>
              </p>
            </div>
          </section>
        </section>

        <section id="three" className="wrapper style3 special">
          <div className="inner">
            <ul id="serv" className="features">
              <li className="icon fa-paper-plane">
                <h3>Masonry Work:</h3>
                <img src={masonry} className="img__home" alt="" />
                <p>
                  Our masonry work focuses on providing high-quality, durable,
                  and aesthetically pleasing construction and restoration
                  services. We specialize in building and repairing stone,
                  brick, and concrete structures, including walls, patios,
                  driveways, fireplaces, and more. With attention to detail and
                  craftsmanship, we ensure that every project not only meets the
                  highest standards of safety and durability but also adds value
                  and beauty to your property.
                </p>
                <p>
                  <Link className="button btn_services" to="/masonrywork">
                    View Photos
                  </Link>
                </p>
              </li>
              <li className="icon solid fa-laptop">
                <h3>Interior Renovation:</h3>
                <img src={interior} className="img__home" alt="" />
                <p>
                  We are experts in converting houses into dream homes. We
                  believe home renovation shouldn’t be a frustrating experience.
                  Watching your project develop and seeing it come to light
                  should be a fulfilling and exciting event. Our Services
                  Include: Basements,Bathrooms,Kitchens,
                  <br />
                  Flooring,Insulation, Drywall,Painting,Baseboards,Crown
                  molding,Trim,Plaster repair, Coffered
                  ceilings,Electrical,Plumbing,
                  <br />
                  Murals,Staircases,Tile setting,Custom cabinetry,Interior
                  waterproofing,Demolition and more!
                </p>
                <p>
                  <Link className="button btn_services" to="/interiorwork">
                    View Photos
                  </Link>
                </p>
              </li>
              <li className="icon solid fa-code">
                <h3>Basement Renovation:</h3>
                <img src={basement} className="img__home" alt="" />
                <p>
                  Are you looking to create basement suite game room, family
                  room, home theater, office, music room, exercise room, extra
                  bedroom or playroom you’ve always wanted? What’s more, this
                  unique basement remodel is particularly well suited for
                  basement environments and includes features such as insulated
                  basement walls and integrated acoustic insulation that can
                  help make your basement feel like a warm and inviting natural
                  extension of your home.
                </p>
                <p>
                  <Link className="button btn_services" to="/basementwork">
                    View Photos
                  </Link>
                </p>
              </li>
              <li className="icon solid fa-headphones-alt">
                <h3 className="title-prb">Bathroom Renovation:</h3>
                <img src={bathroom} className="img__home" alt="" />
                <p>
                  From traditional design to the most modern styles, Mason OM
                  has the largest selection of bathroom products and
                  accessories. Whether you’re looking for a luxury bathroom
                  design or just an update, Mason OM has something for you. We
                  have a big selection and can replicate any bathroom design you
                  may imagine, even something you’ve seen in a magazine or on
                  the web.
                </p>
                <p>
                  <Link className="button btn_services" to="/bathroomwork">
                    View Photos
                  </Link>
                </p>
              </li>
              <li className="icon fa-heart">
                <h3>Kitchen Renovation:</h3>
                <img src={kitchen} className="img__home" alt="" />
                <p>
                  The kitchen is the heart of your home, the place where you and
                  your family gather. The foresight to match the right products
                  for functionality, and budget is critical. Let our experts
                  guide you through our remodelling process in order to achieve
                  a successful kitchen remodelling project.
                </p>
                <p>
                  <Link className="button btn_services" to="/kitchenwork">
                    View Photos
                  </Link>
                </p>
              </li>
              <li className="icon fa-flag">
                <h3>Parking Renovation:</h3>
                <img src={parking} className="img__home" alt="" />
                <p>
                  We specialize in parking renovation, transforming outdated
                  parking facilities into modern, efficient, and well-maintained
                  spaces. Our services include resurfacing, structural repairs,
                  line marking, and the integration of new technologies for
                  improved functionality and user experience.
                </p>
                <p>
                  <Link className="button btn_services" to="/parkingwork">
                    View Photos
                  </Link>
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="contact">
          <h2>Contact Us</h2>

          <div className="contact__info">
            <a href="tel:4168019189" className="icon solid fa-phone">
              {" "}
              (416) 801-9189
            </a>
            <a href="tel:4378737390" className="icon solid fa-phone">
              {" "}
              (437) 873-7390
            </a>
            <a
              href="mailto:masonomcanada@gmail.com"
              className="contact__info-email"
            >
              masonomcanada@gmail.com
            </a>
          </div>
        </section>
      </div>
    </Section>
  );
};
