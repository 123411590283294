import { Link } from "react-router-dom";
import kitchen1 from "./images/kitchen-1.jpg";
import kitchen2 from "./images/kitchen-2.jpg";
import kitchen3 from "./images/kitchen-3.jpg";
import kitchen4 from "./images/kitchen-4.jpg";

import { Section } from "./Services.styled";

export const KitchenWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Kitchen Renovation</h2>
          <div className="gallery">
            <img src={kitchen1} alt="Basement Renovation" />
            <img src={kitchen2} alt="Basement Renovation" />
            <img src={kitchen3} alt="Basement Renovation" />
            <img src={kitchen4} alt="Basement Renovation" />
          </div>
        </div>
        <Link className="button" to="/">
          Go Back
        </Link>
      </div>
    </Section>
  );
};
