import { Section } from "./Contact.styled";

export const Contact = () => {
  return (
    <Section>
      <div className="container">
        <h1>Contact Us</h1>
        <p>
          If you have any questions or inquiries, feel free to reach out to us.
          We are here to help you with your renovation needs!
        </p>
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p>
            <strong>Phone:</strong> (416) 801-9189
          </p>
          <p>
            <strong>Phone:</strong> (437) 873-7390
          </p>
          <p>
            <strong>Email:</strong> masonomcanada@gmail.com
          </p>
        </div>
      </div>
    </Section>
  );
};
