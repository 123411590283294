import styled from "styled-components";

export const Section = styled("section")`
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 66px;
    text-align: center;
    background-color: #2e3842; /* Задаем фоновый цвет */
    color: white; /* Цвет текста */
  }

  h1 {
    margin-bottom: 40px;
  }

  .gallery {
    display: flex; /* Используем flexbox для выравнивания изображений в ряд */
    flex-wrap: wrap; /* Переносим изображения на следующую строку, если не помещаются */
    justify-content: space-between; /* Центруем изображения по горизонтали с равномерным распределением */
  }

  .image-container {
    position: relative; /* Относительное позиционирование для текста */
    margin: 10px; /* Отступы между изображениями */
    flex: 1 1 calc(33.33% - 20px); /* Каждое изображение занимает 1/3 ширины контейнера */
  }

  .image-container img {
    width: 100%; /* Ширина изображения 100% от родительского контейнера */
    height: 300px; /* Высота будет пропорциональна */
    border-radius: 8px; /* Скругленные углы */
    transition: transform 0.3s; /* Плавный эффект при наведении */
    cursor: pointer; /* Курсор указывает на возможность взаимодействия */
  }

  .image-container img:hover {
    transform: scale(1.05); /* Увеличение изображения при наведении */
  }

  .image-container p {
    margin-top: 5px; /* Отступ между изображением и описанием */
  }

  /* Добавляем медиа-запрос для адаптивности */
  @media (max-width: 768px) {
    .image-container {
      flex: 1 1 calc(50% - 20px); /* Две картинки в ряд на меньших экранах */
    }
  }

  @media (max-width: 480px) {
    .image-container {
      flex: 1 1 calc(100% - 20px); /* Одна картинка в ряд на мобильных экранах */
    }
  }
`;
