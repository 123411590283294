import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false); // Хук состояния для управления меню

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen); // Переключение состояния меню
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header id="header" className="alt">
      <h1>
        <NavLink to="/">Mason OM</NavLink>
      </h1>
      <nav id="nav">
        <ul className={isMenuOpen ? "active" : ""}>
          <li>
            <NavLink to="/" exact="true" onClick={closeMenu}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/pastwork" exact="true" onClick={closeMenu}>
              Past Work
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" exact="true" onClick={closeMenu}>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact="true" onClick={closeMenu}>
              Contact Us
            </NavLink>
          </li>
        </ul>
        <div className="burger-menu" onClick={toggleMenu}>
          &#9776;
        </div>
      </nav>
    </header>
  );
};
