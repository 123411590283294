import { Link } from "react-router-dom";
import { Section } from "./Services.styled";

import basement1 from "./images/basement-1.jpg";
import basement2 from "./images/basement-2.jpg";
import basement3 from "./images/basement-3.jpg";
import basement4 from "./images/basement-4.jpg";

export const BasementWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Basement Renovation</h2>
          <div className="gallery">
            <img src={basement1} alt="Kitchen Renovation" />
            <img src={basement2} alt="Kitchen Renovation" />
            <img src={basement3} alt="Kitchen Renovation" />
            <img src={basement4} alt="Kitchen Renovation" />
          </div>
          <Link className="button" to="/">
            Go Back
          </Link>
        </div>
      </div>
    </Section>
  );
};
