import { Route, Routes } from "react-router-dom";

import { Layout } from "./components/Layout/Layout";
import { HomePage } from "./pages/HomePage/HomePage";
import { ServicesPage } from "./pages/ServicesPage/ServicesPage";
import { PastWorkPage } from "./pages/PortfolioPage/PastWorkPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { ParkingPage } from "./pages/ParkingPage/ParkingPage";
import { MasonryPage } from "./pages/MasonryPage.jsx/MasonryPage";
import { InteriorPage } from "./pages/InteriorPage/InteriorPage";
import { BasementPage } from "./pages/BasementPage/BasementPage";
import { BathroomPage } from "./pages/BathroomPage/BathroomPage";
import { KitchenPage } from "./pages/KitchenPage.jsx/KitchenPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/pastwork" element={<PastWorkPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/parkingwork" element={<ParkingPage />} />
        <Route path="/masonrywork" element={<MasonryPage />} />
        <Route path="/interiorwork" element={<InteriorPage />} />
        <Route path="/bathroomwork" element={<BathroomPage />} />
        <Route path="/basementwork" element={<BasementPage />} />
        <Route path="/kitchenwork" element={<KitchenPage />} />
      </Route>
    </Routes>
  );
}

export default App;
