import styled from "styled-components";

export const Section = styled("section")`
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }

  h1 {
    padding-top: 66px;
    margin-bottom: 20px;
  }

  .contact-info {
    margin-top: 20px;
  }

  strong {
    color: #f1c40f; /* Цвет для выделения текста */
  }
`;
