import { Link } from "react-router-dom";
import bathroom1 from "./images/bathroom-1.jpg";
import bathroom2 from "./images/bathroom-2.jpg";
import bathroom3 from "./images/bathroom-3.jpg";
import bathroom4 from "./images/bathroom-4.jpg";

import { Section } from "./Services.styled";

export const BathroomWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Bathroom Renovation</h2>
          <div className="gallery">
            <img src={bathroom1} alt="Basement Renovation" />
            <img src={bathroom2} alt="Basement Renovation" />
            <img src={bathroom3} alt="Basement Renovation" />
            <img src={bathroom4} alt="Basement Renovation" />
          </div>
          <Link className="button" to="/">
            Go Back
          </Link>
        </div>
      </div>
    </Section>
  );
};
