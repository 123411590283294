import styled from "styled-components";

export const Section = styled("section")`
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    padding-top: 62px;
  }

  h1 {
    margin-bottom: 40px;
  }

  .service {
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 28px;
  }

  .gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .gallery img {
    width: calc(
      50% - 20px
    ); /* Устанавливаем ширину изображения так, чтобы они были в три ряда с отступами */
    height: auto; /* Высота будет пропорциональна */
    margin: 10px; /* Отступы между изображениями */
    border-radius: 8px; /* Скругленные углы */
    transition: transform 0.3s; /* Плавный эффект при наведении */
  }

  .gallery img:hover {
    transform: scale(1.05); /* Увеличение изображения при наведении */
  }
`;
