import { Section } from "./Services.styled";
import parking1 from "./images/parking-1.jpg";
import parking2 from "./images/parking-2.jpg";
import parking3 from "./images/parking-3.jpg";
import parking4 from "./images/parking-4.jpg";

import basement1 from "./images/basement-1.jpg";
import basement2 from "./images/basement-2.jpg";
import basement3 from "./images/basement-3.jpg";
import basement4 from "./images/basement-4.jpg";

import masonry1 from "./images/masonry-1.jpg";
import masonry2 from "./images/masonry-2.jpg";
import masonry3 from "./images/masonry-3.jpg";
import masonry4 from "./images/masonry-4.jpg";

import interior1 from "./images/interior-1.jpg";
import interior2 from "./images/interior-2.jpg";
import interior3 from "./images/interior-3.jpg";
import interior4 from "./images/interior-4.jpg";

import kitchen1 from "./images/kitchen-1.jpg";
import kitchen2 from "./images/kitchen-2.jpg";
import kitchen3 from "./images/kitchen-3.jpg";
import kitchen4 from "./images/kitchen-4.jpg";

import bathroom1 from "./images/bathroom-1.jpg";
import bathroom2 from "./images/bathroom-2.jpg";
import bathroom3 from "./images/bathroom-3.jpg";
import bathroom4 from "./images/bathroom-4.jpg";

export const Service = () => {
  return (
    <Section>
      <div className="container">
        <h1>Our Services</h1>
        <div className="service">
          <h2>Parking Renovation</h2>
          <div className="gallery">
            <img src={parking1} alt="Parking Renovation" />
            <img src={parking2} alt="Parking Renovation" />
            <img src={parking3} alt="Parking Renovation" />
            <img src={parking4} alt="Parking Renovation" />
          </div>
        </div>
        <div className="service">
          <h2>Basement Renovation</h2>
          <div className="gallery">
            <img src={basement1} alt="Kitchen Renovation" />
            <img src={basement2} alt="Kitchen Renovation" />
            <img src={basement3} alt="Kitchen Renovation" />
            <img src={basement4} alt="Kitchen Renovation" />
          </div>
        </div>
        <div className="service">
          <h2>Masonry Work</h2>
          <div className="gallery">
            <img src={masonry1} alt="Masonry Work" />
            <img src={masonry2} alt="Masonry Work" />
            <img src={masonry3} alt="Masonry Work" />
            <img src={masonry4} alt="Masonry Work" />
          </div>
        </div>
        <div className="service">
          <h2>Interior Renovation</h2>
          <div className="gallery">
            <img src={interior1} alt="Interior Renovation" />
            <img src={interior2} alt="Interior Renovation" />
            <img src={interior3} alt="Interior Renovation" />
            <img src={interior4} alt="Interior Renovation" />
          </div>
        </div>
        <div className="service">
          <h2>Kitchen Renovation</h2>
          <div className="gallery">
            <img src={kitchen1} alt="Basement Renovation" />
            <img src={kitchen2} alt="Basement Renovation" />
            <img src={kitchen3} alt="Basement Renovation" />
            <img src={kitchen4} alt="Basement Renovation" />
          </div>
        </div>
        <div className="service">
          <h2>Bathroom Renovation</h2>
          <div className="gallery">
            <img src={bathroom1} alt="Basement Renovation" />
            <img src={bathroom2} alt="Basement Renovation" />
            <img src={bathroom3} alt="Basement Renovation" />
            <img src={bathroom4} alt="Basement Renovation" />
          </div>
        </div>
      </div>
    </Section>
  );
};
