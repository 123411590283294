import { Section } from "./Portfolio.styled";

import pastwork1 from "./images/pastwork-1.jpg";
import pastwork2 from "./images/pastwork-2.jpg";
import pastwork3 from "./images/pastwork-3.jpg";
import pastwork4 from "./images/pastwork-4.jpg";
import pastwork5 from "./images/pastwork-5.jpg";
import pastwork6 from "./images/pastwork-6.jpg";
import pastwork7 from "./images/pastwork-7.jpg";
import pastwork8 from "./images/pastwork-8.jpg";
import pastwork9 from "./images/pastwork-9.jpg";
import pastwork10 from "./images/pastwork-10.jpg";
import pastwork11 from "./images/pastwork-11.jpg";
import pastwork12 from "./images/pastwork-12.jpg";
import pastwork13 from "./images/pastwork-13.jpg";
import pastwork14 from "./images/pastwork-14.jpg";
import pastwork15 from "./images/pastwork-15.jpg";
import pastwork16 from "./images/pastwork-16.jpg";
import pastwork17 from "./images/pastwork-17.jpg";
import pastwork18 from "./images/pastwork-18.jpg";
import pastwork19 from "./images/pastwork-19.jpg";
import pastwork20 from "./images/pastwork-20.jpg";
import pastwork21 from "./images/pastwork-21.jpg";
import pastwork22 from "./images/pastwork-22.jpg";

export const PastWork = () => {
  const images = [
    {
      src: pastwork1,
      alt: "Description of Image 1",
    },
    {
      src: pastwork2,
      alt: "Description of Image 2",
    },
    {
      src: pastwork3,
      alt: "Description of Image 3",
    },
    {
      src: pastwork4,
      alt: "Description of Image 3",
    },
    {
      src: pastwork5,
      alt: "Description of Image 3",
    },
    {
      src: pastwork6,
      alt: "Description of Image 3",
    },
    {
      src: pastwork7,
      alt: "Description of Image 3",
    },
    {
      src: pastwork8,
      alt: "Description of Image 3",
    },
    {
      src: pastwork9,
      alt: "Description of Image 3",
    },
    {
      src: pastwork10,
      alt: "Description of Image 3",
    },
    {
      src: pastwork11,
      alt: "Description of Image 3",
    },
    {
      src: pastwork12,
      alt: "Description of Image 3",
    },
    {
      src: pastwork13,
      alt: "Description of Image 3",
    },
    {
      src: pastwork14,
      alt: "Description of Image 3",
    },
    {
      src: pastwork15,
      alt: "Description of Image 3",
    },
    {
      src: pastwork16,
      alt: "Description of Image 3",
    },
    {
      src: pastwork17,
      alt: "Description of Image 3",
    },
    {
      src: pastwork18,
      alt: "Description of Image 3",
    },
    {
      src: pastwork19,
      alt: "Description of Image 3",
    },
    {
      src: pastwork20,
      alt: "Description of Image 3",
    },
    {
      src: pastwork21,
      alt: "Description of Image 3",
    },
  ];

  return (
    <Section>
      <div className="container">
        <h1>Past Work</h1>
        <div className="gallery">
          {images.map((image, index) => (
            <div className="image-container" key={index}>
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};
