import { Link } from "react-router-dom";
import { FooterContainer } from "./Footer.styled";
import logo from "../../img/logo.png";

export const Footer = () => {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>© 2024 Mason OM. All rights reserved.</li>
      </ul>
    </footer>
  );
};
