import { Link } from "react-router-dom";
import masonry1 from "./images/masonry-1.jpg";
import masonry2 from "./images/masonry-2.jpg";
import masonry3 from "./images/masonry-3.jpg";
import masonry4 from "./images/masonry-4.jpg";

import { Section } from "./Services.styled";

export const MasonryWork = () => {
  return (
    <Section>
      <div className="container">
        <div className="service">
          <h2>Masonry Work</h2>
          <div className="gallery">
            <img src={masonry1} alt="Masonry Work" />
            <img src={masonry2} alt="Masonry Work" />
            <img src={masonry3} alt="Masonry Work" />
            <img src={masonry4} alt="Masonry Work" />
          </div>
        </div>
        <Link className="button" to="/">
          Go Back
        </Link>
      </div>
    </Section>
  );
};
